import { Header } from "../components/header/header";
import { Footer } from "../components/footer/footer";
import { AboutUsComponent } from "../components/about/about";

export function AboutUs() {
	return (
		<>
			{/* <Header /> */}
			<AboutUsComponent />
			<Footer />
		</>
	);
}
