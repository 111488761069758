import { styles } from "../warranty/warranty.css";

export function Guidance() {
	return (
		<div className="bottom__text">
			<span className="heading">РУКОВОДСТВО ПО СТАНДАРТНЫМ ГАРАНТИЯМ</span>
			<div className="bottom__text-standart">
				<span className="standart-heading">
					СТАНДАРТНАЯ ГАРАНТИЯ НА ПРОДУКЦИЮ «Ssmart»
				</span>
				<p className="standart-text">
					«Ssmart» гарантирует бесплатный ремонт своей продукции в течение
					гарантийного срока через свои авторизованные сервисные центры. На
					каждый продукт Ssmart действуют разные условия гарантии, подробности
					смотрите ниже. Обратите внимание: приведенная ниже информация
					предназначена только для ознакомления, а Условия и положения могут
					отличаться в зависимости от вашей модели и использования продукта.
				</p>
				<p className="standart-text">
					Гарантия от Ssmart предусматривает бесплатный ремонт в установленные
					сроки и на условиях, описанных ниже. Гарантия Ssmart не ограничивает
					законных прав покупателя. Данная гарантия действительна только в
					отношении первоначального покупателя продукта а также только на
					товары, произведенные для Республики Узбекистан. При необходимости
					воспользоваться гарантией Ssmart обратитесь к в службу поддержки
					клиентов.
				</p>
			</div>
			<div className="bottom__text-warranty">
				<span className="standart-heading">УСЛОВИЯ ГАРАНТИИ</span>
				<p className="standart-text">
					Гарантия «Ssmart» распространяется на дефекты материалов и
					производственные дефекты. Вследствие этого гарантия не
					распространяется на все другие дефекты и убытки в результате
					неправильного обращения, неверных настроек, неверных калибровок и
					установок, несоблюдения требований Руководства пользователя или
					Руководства по установке, а также на повреждения под воздействием
					внешних факторов и в результате нормального износа и косметические
					повреждения.
				</p>
				<p className="standart-text">
					Действие гарантии «Ssmart» распространяется только на дефекты
					материалов и производственные дефекты, уведомления о которых получены
					в период действия гарантии «Ssmart».
				</p>
				<p className="standart-text">
					Гарантия «Ssmart» действует с даты фактической покупки, и
					рекомендуется сохранять оригинал (не в рукописной форме, кассовый чек)
					документа о покупке в течение гарантийного срока. Гарантия «Ssmart»
					действительна только после предъявления оригинального (не в рукописной
					форме) документа о покупке.
				</p>
				<p className="standart-text">
					Гарантия «Ssmart» теряет силу после работ по изменению конструкции или
					ремонту, выполненных лицами, не уполномоченными «Ssmart», при подделке
					или удалении серийного номера, после модернизации или
					профессионального использования потребительского товара.
				</p>
				<p className="standart-text">
					Решение о ремонте принимается исключительно «Ssmart». Замененные
					детали и узлы становятся собственностью «Ssmart». Замененные блоки и
					запасные части могут быть как новыми, так и восстановленными.
				</p>
				<p className="standart-text">
					Гарантия «Ssmart» не предусматривает других расходов, кроме расходов
					на ремонт или замену.
				</p>
				<p className="standart-text">
					«Ssmart» не несет ответственности за любого рода утрату или
					повреждения программного обеспечения или носителей информации.
				</p>
				<p className="standart-text">
					Замененные детали и узлы становятся собственностью организации,
					которая осуществила замену или ремонт.
				</p>
			</div>
		</div>
	);
}
