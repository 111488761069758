export const words = {
	ru: {
		home: "Главная",
		products: "Продукция",
		dealers: "Дилерам",
		warranty: "Сервис/Гарантия",
		about: "О компании",
		contacts: "Контакты",
		support: "Поддержка",
		new: "НОВИНКА",
		webos: "Smart TV на базе",
		display_top: "Дисплей с яркими ",
		display_bottom: "Цветами",
		no_frame_top: "БЕЗРАМОЧНЫЙ",
		no_frame_bottom: "Дизайн корпуса",
		services_title: "ВСТРОЕННЫЕ СЕРВИСЫ",
		services_description:
			"При первом включении доступ к ТВ каналам, Фильмам Сериалам и другому контенту.",
		dolby_title: "НЕВЕРОЯТНЫЙ",
		dolby_description: "Звук от Dolby Digital",
		os_title: "ОПЕРАЦИОННАЯ СИСТЕМА И МАГАЗИН ПРИЛОЖЕНИЙ ",
		os_description: "ОТ КОМПАНИИ LG",
		products_heading: "ПРОДУКЦИЯ",
		currency: "Cум",
		buy: "Купить",
		more: "Подробнее",
		service_heading: "Гарантия и Сервис",
		service_left_title: "СЕРВИС",
		addresses: "Адреса",
		service_points: "Сервисные центры",
		warranty_conditions: "УСЛОВИЯ ГАРАНТИИ",
		warranty_text: "Узнать подробнее",
		where_to_buy: "ГДЕ КУПИТЬ ?",
		partners_heading: "Партнеры",
		character: "Характеристики",
		display: "Экран",
		resolution: "Разрешения",
		brightness: "Яркость",
		contrast: "Контраст",
		sound: "Звук",
		ac: "Блок Питания",
		system: "Система",
		voice: "Голосовое управление",
		tech: "Технологии",
	},
	en: {
		home: "Home",
		products: "Products",
		dealers: "For dealers",
		warranty: "Service/Warranty",
		about: "About us",
		contacts: "Contacts",
		support: "Support",
		new: "NEW",
		webos: "Smart TV based on",
		display_top: "Display with bright",
		display_bottom: "Colors",
		no_frame_top: "FRAMELESS",
		no_frame_bottom: "Corpus design",
		services_title: "BUILT-IN SERVICES",
		services_description:
			"When you first turn on, access to TV channels, Movies, TV shows and other content.",
		dolby_title: "INCREDIBLE",
		dolby_description: "Sound from Dolby Digital",
		os_title: "OPERATING SYSTEM AND APPLICATION STORE ",
		os_description: "FROM LG",
		products_heading: "PRODUCTS",
		currency: "Sum",
		buy: "Buy",
		more: "More",
		service_heading: "Warranty and Service",
		service_left_title: "SERVICE",
		addresses: "Addresses",
		service_points: "Service points",
		warranty_conditions: "WARRANTY CONDITIONS",
		warranty_text: "Learn more",
		where_to_buy: "WHERE TO BUY ?",
		partners_heading: "Partners",
		character: "Characteristics",
		display: "Display",
		resolution: "Resolution",
		brightness: "Brightness",
		contrast: "Contrast",
		sound: "Sound",
		ac: "Power unit",
		system: "System",
		voice: "Voice control",
		tech: "Technologies",
	},
	uz: {
		home: "Bosh sahifa",
		products: "Mahsulotlar",
		dealers: "Dillerlar uchun",
		warranty: "Xizmat ko'rsatish/Kafolat",
		about: "Kompaniya haqida",
		contacts: "Kontaktlar",
		support: "Yordam",
		new: "YANGI",
		webos: "Smart TV asosida",
		display_top: "Rangli ekran",
		display_bottom: "Bilan",
		no_frame_top: "Chegarasiz",
		no_frame_bottom: "Korpus dizayni",
		services_title: "O'RNATILGAN XIZMATLAR",
		services_description:
			"Birinchi marta yoqilganda, TV kanallariga, Filmlarga, TV dasturlariga va boshqa dasturlarga kirish imkoniyati.",
		dolby_title: "AJOYIB",
		dolby_description: "Dolby Digital ovozi",
		os_title: "OPERATSION TIZIM VA DASTUR DO'KONI ",
		os_description: "LG KOMPANIYASIDAN",
		products_heading: "MAHSULOTLAR",
		currency: "So'm",
		buy: "Sotib olish",
		more: "Batafsil",
		service_heading: "Kafolat va Xizmat",
		service_left_title: "XIZMAT",
		addresses: "Manzillar",
		service_points: "Xizmat markazlari",
		warranty_conditions: "KAFOLAT SHARTLARI",
		warranty_text: "Batafsil ma'lumot",
		where_to_buy: "QAYERDAN SOTIB OLSA BO'LADI ?",
		partners_heading: "Hamkorlar",
		character: "Ma'lumot",
		display: "Ekran",
		resolution: "O'lchami",
		brightness: "Yorug'ligi",
		contrast: "Kontrast",
		sound: "Ovozi",
		ac: "Quvvat bloki",
		system: "Sistema",
		voice: "Ovoz bilan boshqarish",
		tech: "Texnologiyalar",
	},
};

export const footer = {
	ru: {
		support: "ПОДДЕРЖКА",
		about: "О НАС",
		products: "ПРОДУКЦИЯ",
		contact: "СВЯЗАТЬСЯ С НАМИ",
	},
	en: {
		support: "SUPPORT",
		about: "ABOUT US",
		products: "PRODUCTS",
		contact: "CONTACT US",
	},
	uz: {
		support: "YORDAM",
		about: "BIZ HAQIMIZDA",
		products: "MAHSULOTLAR",
		contact: "BIZ BILAN BOG'LANING",
	},
};
